import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "container nav-list" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "flex home__content" }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 0,
  class: "content"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "title" }
const _hoisted_10 = {
  key: 1,
  class: "nav-tips"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isMobile())
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _cache[0] || (_cache[0] = [
            _createElementVNode("span", { style: { whiteSpace: 'nowrap' } }, "英荔 AI 创造乐园", -1),
            _createElementVNode("br", null, null, -1),
            _createTextVNode(" 互动项目 ")
          ])))
        : (_openBlock(), _createElementBlock("h1", _hoisted_4, "英荔 AI 创造乐园 互动项目")),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (nav, index) => {
          return (_openBlock(), _createElementBlock("a", {
            key: index,
            href: nav.href
          }, [
            (nav.type === 'link')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("img", {
                    src: nav.cover,
                    alt: ""
                  }, null, 8, _hoisted_8),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(nav.title), 1)
                ]))
              : (nav.type === 'tips')
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[1] || (_cache[1] = [
                    _createElementVNode("p", null, "更多项目即将上线，详情请咨询英荔", -1),
                    _createTextVNode(" 教育产品经理 ")
                  ])))
                : _createCommentVNode("", true)
          ], 8, _hoisted_6))
        }), 128))
      ])
    ])
  ]))
}