<template>
    <div class="desc-page">
        <div class="desc-page__content">
            <div class="desc-page__left">
                <div class="desc-page__title">英荔 AI 应用平台</div>
                <div class="desc-page__tips">
                    英荔教育研发的面向青少年的生成式人工智能应用平台，在保证隐私和内容安全的前提下，汇聚了
                    AI 对话、文生图、文生视频、文生音乐、AI 生成
                    PPT、图像处理等多项 AI
                    功能，限时面向公众免费开放注册（还赠送算力哦）
                </div>
                <div class="desc-page__image-section">
                    <img
                        class="desc-page__image"
                        :src="require('@/assets/images/desc-qrcode.png')"
                        alt=""
                    />
                    <div class="desc-page__terminal-support">
                        AI 应用平台支持在平板电脑、手机等多终端上使用
                    </div>
                </div>
                <button class="desc-page__button" @click="link">
                    立即前往
                </button>
            </div>
            <div class="desc-page__right">
                <img
                    class="desc-page__paid"
                    :src="require('@/assets/images/desc-paid.png')"
                />
                <img
                    class="desc-page__cong"
                    :src="require('@/assets/images/desc-cong.png')"
                />
            </div>
        </div>
        <div class="desc-page__version">产品版本：{{ version }}</div>
    </div>
</template>
<script>
import { defineComponent, onMounted, onBeforeUnmount, ref } from 'vue';
import { getVersion } from '@/api/index';
export default defineComponent({
    name: 'desc-page',
    setup() {
        onMounted(() => {
            const app = document.querySelector('#app');
            app.style.height = '100%';
            app.style.display = 'flex';
            app.style.flexDirection = 'column';
            app.style.paddingTop = '';
        });
        onBeforeUnmount(() => {
            const app = document.querySelector('#app');
            app.style.height = '';
            app.style.display = '';
            app.style.flexDirection = '';
            app.style.paddingTop = '96px';
        });
        const link = () => {
            window.location.href =
                'https://app.aimaker.space/auth?type=register&invite=Mw';
        };
        const version = ref('');
        onMounted(() => {
            getVersion('app').then(res => {
                if (res.status === 200) {
                    console.log(res.data.version, 'res??');
                    version.value = res.data.version;
                }
            });
        });
        return {
            link,
            version,
        };
    },
});
</script>
<style lang="scss">
.desc-page {
    position: relative;
    box-sizing: border-box;
    flex: 1;
    background-image: linear-gradient(139deg, #2b90fd 0%, #00caff 100%);
    background-size: 100% 500px;
    background-clip: content-box;
    background-repeat: no-repeat;
    .desc-page__content {
        display: flex;
        // justify-content: space-between;
        position: relative;
        padding: 60px 128px 0 198px;
    }
    .desc-page__title {
        height: 34px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 34px;
        color: #ffffff;
        line-height: 34px;
        text-align: left;
        font-style: normal;
    }
    .desc-page__tips {
        margin-top: 30px;
        width: 491px;
        height: 74px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        line-height: 24px;
        text-align: left;
        font-style: normal;
    }
    .image-section {
        display: flex;
        align-items: center;
    }
    .desc-page__image-section {
        display: flex;
        align-items: center;
        margin-top: 30.5px;
        .desc-page__image {
            width: 90px;
            height: 90px;
            margin-right: 24px;
        }
        .desc-page__terminal-support {
            width: 226px;
            height: 44px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 22px;
            text-align: left;
            font-style: normal;
        }
    }
    .desc-page__button {
        margin-top: 30px;
        width: 120px;
        height: 46.5px;
        background: linear-gradient(112deg, #f6851c 0%, #ffb904 100%);
        box-shadow: 0px 2px 6px 0px rgba(0, 132, 255, 0.52);
        border-radius: 23px;
        border: none;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        font-style: normal;
        cursor: pointer;
    }
    .desc-page__paid {
        width: 515px;
    }
    .desc-page__left {
        margin-top: 15px;
        margin-right: 108px;
    }
    .desc-page__cong {
        // position: absolute;
        // top: 300px;
        // right: 229px;
        margin-top: -200px;
        transform: translateX(100px);
        width: 312px;
        height: 380px;
    }
    .desc-page__version {
        position: absolute;
        right: 43px;
        bottom: 25px;
        width: 120px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 16px;
        text-align: center;
        font-style: normal;
    }
}
</style>
