<template lang="">
    <div class="mobile">
        <h3>创造乐园门店 图形化编程作品平台</h3>
        <div class="desc">
            <p>
                图形化编程作品平台内含大量英荔优秀学员过往的作品改编而成的互动体验游戏，是英荔「先体验、再改编、后创造、能分享」自驱力学习法的核心体现。
            </p>
            <p>
                本平台无法在手机端运行，请在电脑上打开本平台以获得最佳体验。还存在其他问题请点击「聊一聊」联系产品经理。
            </p>
        </div>
        <div class="equipment">
            <h3 class="title">相关硬件设备</h3>
            <div class="list">
                <p>1. 显示器</p>
                <p>2. 迷你主机</p>
                <p>3. 配套外设（USB 摄像头等）</p>
            </div>
        </div>
        <div class="course">
            <h3>相关教程</h3>
            <div>
                <div
                    class="image"
                    v-for="(video, index) in videos"
                    :key="index"
                >
                    <img :src="video.poster" alt="poster" class="poster" />
                    <div class="mask">
                        <img
                            class="playIcon"
                            @click="openVideo(index)"
                            src="../assets/images/play.png"
                        />
                    </div>
                </div>
            </div>
        </div>
        <a-modal
            :open="curVideo.video"
            centered
            :footer="null"
            @cancel="closeVideo"
            width="92vw"
            class="video-modal"
            :title="curVideo.title"
            :destroyOnClose="true"
        >
            <div class="video-box">
                <video
                    autoplay=""
                    id="mobile-video"
                    :style="{ width: '100%', height: '100%' }"
                ></video>
            </div>
        </a-modal>
    </div>
</template>
<script>
import { ref } from 'vue';
// import store from '@/store';
import TCPlayer from 'tcplayer.js';
import 'tcplayer.js/dist/tcplayer.min.css';
const poster1 = require('@/assets/images/poster1.png');
const poster2 = require('@/assets/images/poster2.png');
const poster3 = require('@/assets/images/poster3.png');
const playerContainerId = ref(null);
export default {
    name: 'MobilePage',
    setup() {
        const videos = ref([
            {
                poster: poster1,
                video: 'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/919deb2e1397757900406660739/v.f40673.mp4',
                videoId: '1397757900406660739',
                title: '如何组装迷你主机',
            },
            {
                poster: poster2,
                video: 'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/de98123f1397757900407660201/v.f40673.mp4',
                videoId: '1397757900407660201',
                title: '如何将迷你主机安装到显示器背面',
            },
            {
                poster: poster3,
                video: 'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/d7d197cc1397757900407395047/v.f40673.mp4',
                videoId: '1397757900407395047',
                title: '如何安装电脑外设',
            },
        ]);
        const player = ref(null);
        const curVideo = ref({
            video: null,
        });
        const openVideo = index => {
            curVideo.value = videos.value[index];
            fetch('https://api.dev.longan.eliteu.xyz/api/v1/vod_sign', {
                method: 'post',
                body: `file_id=${curVideo.value.videoId}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(function (data) {
                    return data.text();
                })
                .then(function (data) {
                    const result = JSON.parse(data);
                    const { license_url, signature } = result;
                    const config = {
                        fileID: curVideo.value.videoId,
                        appID: '1251349076',
                        autoplay: true,
                        psign: signature,
                        licenseUrl: license_url,
                    };
                    player.value = TCPlayer('mobile-video', config);
                });
        };
        const closeVideo = () => {
            curVideo.value = {
                video: null,
            };
            player.value.dispose();
        };
        return {
            videos,
            playerContainerId,
            openVideo,
            curVideo,
            closeVideo,
            player,
        };
    },
};
</script>
<style lang="scss" scoped>
.mobile {
    background: #fff;
    padding: 8.26vw 9.833vw 20vw;
    font-size: 3.467vw;
    color: #666666;
    box-shadow: 0vw 0.267vw 1.067vw 0vw rgba(0, 21, 41, 0.12);
    font-family: PingFangSC, PingFang SC;
    h3 {
        font-weight: 600;
        font-size: 4.8vw;
        color: #333333;
        margin-bottom: 4vw;
        text-align: center;
    }
    .desc {
        p {
            line-height: 5.3vw;
            margin-bottom: 5.4vw;
            text-align: justify;
        }
    }
    .equipment {
        margin-top: 7vw;
        .list {
            p {
                margin-bottom: 6vw;
            }
        }
    }
    .course {
        .image {
            position: relative;
            width: 100%;
            height: 44.533vw;
            border-radius: 1.6vw;
            overflow: hidden;
            background: #000;
            margin-bottom: 4.533vw;
            .poster {
                width: 100%;
                height: 100%;
            }
            .mask {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgb(66 66 66 / 19%);
                .playIcon {
                    width: 20.533vw;
                }
            }
        }
    }
}
.video-box {
    width: 91vw;
    height: 51.2vw;
    margin: 0 auto;
}
</style>
