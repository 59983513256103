<template>
    <div class="login">
        <a-spin size="large" :spinning="loading">
            <div class="login-form">
                <h3>
                    创造乐园门店 <br />
                    图形化编程作品
                </h3>
                <a-form
                    :model="formState"
                    name="basic"
                    :label-col="{ span: 0 }"
                    :wrapper-col="{ span: 24 }"
                    autocomplete="off"
                    @finish="onFinish"
                    @finishFailed="onFinishFailed"
                    class="form"
                >
                    <a-form-item
                        name="store_no"
                        :rules="[{ required: true, message: '请输入门店 ID' }]"
                    >
                        <a-input
                            v-model:value="formState.store_no"
                            placeholder="门店 ID"
                            :style="{ height: '40px', padding: '4px 14px' }"
                        />
                    </a-form-item>

                    <a-form-item
                        name="store_password"
                        :rules="[{ required: true, message: '请输入密码' }]"
                    >
                        <a-input-password
                            placeholder="密码"
                            v-model:value="formState.store_password"
                            :style="{ height: '40px', padding: '4px 14px' }"
                        />
                    </a-form-item>

                    <a-form-item :wrapper-col="{ offset: 0, span: 24 }">
                        <a-button
                            type="primary"
                            html-type="submit"
                            :style="{ width: '100%', height: '40px' }"
                            :disabled="isAgree"
                            >登录</a-button
                        >
                    </a-form-item>
                    <p>忘记密码时请联系英荔客服专员</p>
                </a-form>

                <div class="agree">
                    <a-checkbox v-model:checked="formState.agree"></a-checkbox>
                    <span
                        >本平台仅限英荔教育集团内部使用。为确保正常运行，会调用计算机的网络适配器、扬声器、麦克风、摄像头、蓝牙设备等并收集相关使用信息。</span
                    >
                </div>
            </div>
        </a-spin>
        <video ref="video" autoplay id="video"></video>
        <canvas ref="canvas" id="canvas"></canvas>
        <a-modal
            :open="showBroswerTips"
            :title="false"
            centered
            :footer="null"
            :maskClosable="false"
            :closable="false"
            width="557px"
        >
            <div class="broswer-tips">
                <div class="tip">
                    英荔图形化编程作品平台仅限使用特定的浏览器打开，<br />请点击下方的浏览器，下载安装
                </div>
                <div class="broswer-list">
                    <div
                        v-for="broswer in broswerList"
                        :key="broswer.name"
                        class="broswer"
                    >
                        <a :href="broswer.url" target="_blank">
                            <img :src="broswer.img" :alt="broswer.name" />
                            <div class="name">
                                <span>{{ broswer.name }}</span>
                            </div>
                            <div class="desc">{{ broswer.desc }}</div>
                        </a>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script lang="ts">
interface FormState {
    store_no: string;
    store_password: string;
    login_type?: number;
    agree?: boolean;
}
import { defineComponent, reactive, ref, watch } from 'vue';
import Cookies from 'js-cookie';
import { storeLogin, deviceLoginLog, getVersion } from '@/api/index';
import store from '@/store';
import { message } from 'ant-design-vue';
import router from '@/router';

import { BrowserType } from '@/utils/browserPrompt.js';
const chromeImg = require('@/assets/images/Chrome.png');
const firefoxImg = require('@/assets/images/Firefox.png');
const loginResMap: any = {
    203: '门店 ID 或密码错误',
    204: '当前校区已经运行了 1 个 AI 互动游戏平台，无法在另一台计算机上继续进入。请先关闭正在运行的平台，稍候 1 分钟再试。',
    205: 'session 不存在',
    403: '您无权登录',
    207: '当前校区还未开通图形化编程作品平台哦',
};
export default defineComponent({
    name: 'LoginView',
    setup() {
        sessionStorage.setItem('store_token', '');
        sessionStorage.setItem('isLogin', '');
        Cookies.remove('scratchToken', { domain: '.aimaker.space' });
        Cookies.remove('scratchToken', { domain: '.eliteu.xyz' });
        Cookies.remove('scratchToken', { domain: 'localhost' });
        const loading = ref<boolean>(false);
        const showBroswerTips = ref<boolean>(false);
        const formState = reactive<FormState>({
            store_no: '',
            store_password: '',
            agree: true,
        });

        const takePhoto = async () => {
            let user_photo = null;
            try {
                const video = document.getElementById(
                    'video'
                ) as HTMLVideoElement;
                const canvas = document.getElementById(
                    'canvas'
                ) as HTMLCanvasElement;
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true,
                });
                video.srcObject = stream;
                await video.play();
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    user_photo = canvas.toDataURL();
                    stream.getTracks().forEach(track => {
                        track.stop(); // 停止轨道
                    });
                }
            } catch (error) {
                console.log('摄像头未授权');
                user_photo = null;
            }
            return user_photo;
        };
        const getDeviceName = () => {
            const userAgent = navigator.userAgent || navigator.vendor;
            if (
                userAgent.match(/iPad/i) ||
                userAgent.match(/iPhone/i) ||
                userAgent.match(/iPod/i)
            ) {
                return 'iOS';
            } else if (userAgent.match(/Android/i)) {
                return 'Android';
            } else if (userAgent.match(/webOS/i) || userAgent.match(/Palm/i)) {
                return 'Palm';
            } else if (userAgent.match(/BlackBerry/i)) {
                return 'BlackBerry';
            } else if (userAgent.match(/Windows Phone/i)) {
                return 'Windows Phone';
            } else if (userAgent.match(/SymbianOS/i)) {
                return 'Symbian';
            } else if (
                userAgent.match(/Tablet/i) &&
                !userAgent.match(/iPad/i)
            ) {
                return 'Tablet';
            } else if (
                userAgent.match(/Mobile/i) &&
                !userAgent.match(/iPhone/i) &&
                !userAgent.match(/Android/i)
            ) {
                return 'Mobile';
            } else {
                return 'Desktop';
            }
        };
        const getBroswerName = () => {
            var nAgt = navigator.userAgent;
            var browserName = navigator.appName;
            var nameOffset, verOffset;
            // In Opera 15+, the true version is after "OPR/"
            if ((verOffset = nAgt.indexOf('OPR/')) != -1) {
                browserName = 'Opera';
            }
            // In older Opera, the true version is after "Opera" or after "Version"
            else if ((verOffset = nAgt.indexOf('Opera')) != -1) {
                browserName = 'Opera';
            }
            // In MSIE, the true version is after "MSIE" in userAgent
            else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
                browserName = 'Microsoft Internet Explorer';
            }
            // In Chrome, the true version is after "Chrome"
            else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
                browserName = 'Chrome';
            }
            // In Safari, the true version is after "Safari" or after "Version"
            else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
                browserName = 'Safari';
            }
            // In Firefox, the true version is after "Firefox"
            else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
                browserName = 'Firefox';
            }
            // In most other browsers, "name/version" is at the end of userAgent
            else if (
                (nameOffset = nAgt.lastIndexOf(' ') + 1) <
                (verOffset = nAgt.lastIndexOf('/'))
            ) {
                browserName = nAgt.substring(nameOffset, verOffset);
                if (browserName.toLowerCase() == browserName.toUpperCase()) {
                    browserName = navigator.appName;
                }
            }
            return browserName;
        };

        const broswerRes = BrowserType();
        if (
            (broswerRes.supporter === 'chrome' &&
                Number(broswerRes.supporterVs.split('.')[0]) >= 76) ||
            broswerRes.supporter === 'firefox'
        ) {
            console.log('通过', broswerRes);
        } else {
            showBroswerTips.value = true;
        }

        const onFinish = (values: any) => {
            values.login_type = 1;
            loading.value = true;
            storeLogin(values).then(async res => {
                const code = res.data.code as any;
                const content = loginResMap[code];
                const token = res.data.store_token;
                const scratchToken = res.data.scratch_token;
                if (content) {
                    loading.value = false;
                    message.error(content);
                    return;
                }
                sessionStorage.setItem('isLogin', 'true');
                sessionStorage.setItem('store_token', token);
                Cookies.set('scratchToken', token, {
                    domain: '.eliteu.xyz',
                    expires: 1,
                });
                Cookies.set('scratchToken', token, {
                    domain: '.aimaker.space',
                    expires: 1,
                });
                Cookies.set('scratchToken', token, {
                    domain: 'localhost',
                    expires: 1,
                });

                Cookies.set('scratchWorkToken', scratchToken, {
                    domain: '.eliteu.xyz',
                    expires: 1,
                });
                Cookies.set('scratchWorkToken', scratchToken, {
                    domain: '.aimaker.space',
                    expires: 1,
                });
                Cookies.set('scratchWorkToken', scratchToken, {
                    domain: 'localhost',
                    expires: 1,
                });
                const user_photo = await takePhoto();
                const versionResult = await getVersion('blocks-experience');
                const { version } = versionResult.data;
                store.commit('updateInfo', { ...res.data, version });
                await deviceLoginLog({
                    platform_type: 'Scratch',
                    device_name: getDeviceName(),
                    operating_system: navigator.platform.toLowerCase(),
                    browser: getBroswerName(),
                    user_photo: user_photo,
                });
                loading.value = false;
                await router.replace('/blocks-experience/category');
            });
        };

        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
        };
        const isAgree = ref(false);
        watch(
            () => formState.agree,
            val => {
                isAgree.value = !val;
            }
        );
        return {
            formState,
            isAgree,
            loading,
            showBroswerTips,
            broswerList: [
                {
                    img: chromeImg,
                    name: '谷歌 Chrome 浏览器',
                    desc: '（首选，适合几乎所有网页）',
                    url: 'https://www.google.cn/intl/zh-CN/chrome/',
                },
                {
                    img: firefoxImg,
                    name: 'Firefox 火狐浏览器',
                    desc: '（搭配 LEGO 硬件更稳定）',
                    url: 'https://www.mozilla.org/zh-CN/firefox/windows/',
                },
            ],
            takePhoto,
            onFinish,
            onFinishFailed,
        };
    },
});
</script>
<style lang="scss" scoped>
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 96px - 36px);
}
.login-form {
    width: 410px;
    height: 464px;
    background: #ffffff;
    border-radius: 10px;
    padding: 32px 0 37px;
    font-size: 14px;
    h3 {
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        color: #333333;
        margin-bottom: 27px;
        text-align: center;
    }

    p {
        margin-top: -10px;
        margin-bottom: 24px;
        text-align: right;
        color: #999999;
    }

    .form {
        margin: 0 40px;
    }

    .agree {
        display: flex;
        align-items: flex-start;
        color: #999999;
        margin: 5px 30px 0;

        span {
            margin-left: 8px;
            padding-inline-end: 0;
            text-align: justify;
            line-height: 1.5714285714285714;
        }
    }
}

#canvas,
#video {
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.broswer-tips {
    width: 100%;
    text-align: center !important;
    padding: 20px 10px;
    font-size: 16px;
    .tip {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        text-align: center !important;
        margin-bottom: 22px;
    }
    .broswer-list {
        display: flex;
        justify-content: center;
        .broswer {
            margin: 0 30px;
            text-align: center;
            img {
                width: 97px;
                height: 97px;
                object-fit: contain;
                margin-bottom: 12px;
            }
            .name {
                margin-bottom: 4px;
                color: #0084ff !important;
            }
            .desc {
                font-size: 14px;
                color: #999999;
            }
        }
    }
}
</style>
