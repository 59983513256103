<template>
    <div class="home">
        <div class="container nav-list">
            <h1 v-if="isMobile()">
                <span :style="{ whiteSpace: 'nowrap' }">英荔 AI 创造乐园</span>
                <br />
                互动项目
            </h1>
            <h1 v-else>英荔 AI 创造乐园 互动项目</h1>
            <div class="flex home__content">
                <a
                    v-for="(nav, index) in navList"
                    :key="index"
                    :href="nav.href"
                >
                    <!-- 链接部分,点击跳转 -->
                    <template v-if="nav.type === 'link'">
                        <div class="content">
                            <img :src="nav.cover" alt="" />
                            <div class="title">{{ nav.title }}</div>
                        </div>
                        <!-- <img
                            class="bg"
                            src="../assets/images/blueBg.png"
                            alt=""
                        /> -->
                    </template>
                    <template v-else-if="nav.type === 'tips'">
                        <div class="nav-tips">
                            <p>更多项目即将上线，详情请咨询英荔</p>
                            教育产品经理
                        </div>
                    </template>
                </a>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { isMobile } from '@/utils/common';
const p1 = require('@/assets/images/1.png');
const p2 = require('@/assets/images/2.png');
const p3 = require('@/assets/images/3.png');
const p4 = require('@/assets/images/4.png');
const p5 = require('@/assets/images/5.png');
const p6 = require('@/assets/images/6.png');
export default defineComponent({
    name: 'HomeView',
    setup() {
        const origin = window.location.origin;
        const aiShowUrl = origin.includes('.xyz')
            ? 'https://aishow.eliteu.xyz/'
            : 'https://aishow.aimaker.space/';
        const navList = [
            {
                type: 'link',
                href: aiShowUrl,
                cover: p1,
                title: 'AI 互动游戏',
            },
            {
                type: 'link',
                href: `${origin}/blocks-experience/category`,
                cover: p2,
                title: '图形化编程作品',
            },
            {
                type: 'link',
                href: origin.includes('.space')
                    ? `https://hdty-wall.aimaker.space/`
                    : `https://hdty-wall.eliteu.xyz/`,
                cover: p3,
                title: '互动投影（墙面版）',
            },
            {
                type: 'link',
                href: origin.includes('.space')
                    ? `https://showai.aimaker.space/`
                    : `https://showai.eliteu.xyz/`,
                cover: p4,
                title: 'AI 技术展示项目',
            },
            {
                type: 'link',
                href: origin.includes('.space')
                    ? `https://rover.aimaker.space/`
                    : `https://rover.eliteu.xyz/`,
                cover: p5,
                title: '星球探索车',
            },
            {
                type: 'link',
                href: '/desc',
                cover: p6,
                title: 'AI 应用平台【推广】',
            },
            {
                type: 'tips',
            },
        ];
        return {
            aiShowUrl,
            navList,
            isMobile,
        };
    },
});
</script>
<style lang="scss" scoped>
.nav-list {
    text-align: center;
    padding-top: 40px;
    p {
        white-space: nowrap;
        margin: 0;
    }
    h1 {
        font-weight: 600 !important;
        margin: 0;
        line-height: 1;
    }

    .flex {
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        align-items: center;
        row-gap: 24px;
        column-gap: 0;
        padding: 0 48px;
        a {
            width: 312px;
            height: 310px;
            // margin: 0 16px;
            position: relative;
            &:hover {
                .content {
                    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
                }
                .title {
                    color: #0084ff !important;
                }
                // .content {
                //     top: -10px;
                // }
            }

            .bg {
                width: 262px;
                position: absolute;
                left: 50%;
                margin-left: -131px;
                z-index: 1;
                bottom: -30px;
            }

            .content {
                position: absolute;
                border-radius: 10px;
                overflow: hidden;
                z-index: 2;
                .title {
                    font-weight: 500;
                    padding: 16px;
                    background: #fff;
                    color: #333333;
                    font-size: 14px;
                    color: #333333;
                    text-align: left;
                }

                img {
                    width: 100%;
                    height: 246px;
                    // object-fit: contain;
                }
            }
            .nav-tips {
                padding: 30px 26px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #999999;
                line-height: 24px;
                text-align: left;
                font-style: normal;
                width: 312px;
                height: 108px;
                background: #f5f5f5;
                border-radius: 9px;
                border: 1px dashed #979797;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .home {
        .nav-list {
            padding-top: 8vw;
            h1 {
                margin: 0 auto;
                height: 19.2vw;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 6.4vw;
                color: #333333;
                line-height: 9.6vw;
                text-align: center;
                font-style: normal;
            }
            .home__content {
                grid-template-columns: repeat(1, 1fr);
                margin-top: 7.73vw;
                padding: 0 7vw;
                row-gap: 4.27vw;
                column-gap: 0;
            }
            a {
                width: 83.2vw;
                height: 82.67vw;
                // margin: 0 16px;
                position: relative;
                &:hover {
                    .content {
                        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
                    }
                    .title {
                        color: #0084ff !important;
                    }
                }
                .content {
                    .title {
                        padding: 4.27vw;
                        font-size: 3.73vw;
                    }
                    img {
                        // height: 246px;
                        height: 65.33vw;
                        border-radius: 2.67vw 2.67vw 0px 0px;
                    }
                }
                .nav-tips {
                    // 修改为 vw 单位
                    padding: 8vw 7.47vw;
                    font-weight: 400;
                    // 修改为 vw 单位
                    font-size: 4.27vw;
                    // 修改为 vw 单位
                    line-height: 6.4vw;
                    // 修改为 vw 单位
                    width: 83.2vw;
                    // 修改为 vw 单位
                    height: 28.8vw;
                    // 修改为 vw 单位
                    border-radius: 2.4vw;
                }
            }
        }
    }
}
</style>
